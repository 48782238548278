<template>
  <app-module-view>
    <template slot="body">
      <app-subscription-payment-filter></app-subscription-payment-filter>
      <div class="card">
        <div class="card-body">
          <app-preloader v-if="callingAPI"></app-preloader>
          <app-data-table
            :data="payments"
            :totalCount="totalCount"
            :page="page"
            :config="dataTableConfig"
            @page-change="setPageAndGetRecords"
          >
          </app-data-table>
        </div>
      </div>
    </template>
  </app-module-view>
</template>

<script>
import ModuleView from '../ModuleView'
import { mapState } from 'vuex'
import config from '../../config'
import Preloader from '../preloader/Preloader'
import SubscriptionPaymentFilter from './SubscriptionPaymentFilter'
import DataTable from '../shared/DataTable'
import PaymentStatusTypeMixin from '../mixins/valueObject/eshop/PaymentStatusTypeMixin'
import PaymentResultTypeMixin from '../mixins/valueObject/eshop/PaymentResultTypeMixin'
import PaymentTypeMixin from '../mixins/valueObject/eshop/PaymentTypeMixin'
import StoreService from '../../services/eshop/StoreService'

export default {
  name: 'SubscriptionPaymentList',
  mixins: [PaymentStatusTypeMixin, PaymentResultTypeMixin, PaymentTypeMixin],
  data () {
    return {
      page: 1,
      dataTableConfig: {
        fields: {
          id: this.$t('eshopSubscriptionPayment.id'),
          variableSymbol: this.$t('eshopSubscriptionPayment.variableSymbol'),
          order: this.$t('eshopSubscriptionPayment.order'),
          priceInEuro: this.$t('eshopSubscriptionPayment.price'),
          orderProductName: this.$t('eshopSubscriptionPayment.product'),
          orderPayment: this.$t('eshopSubscriptionPayment.payment'),
          paymentResult: this.$t('eshopSubscriptionPayment.paymentResult'),
          orderEmail: this.$t('eshopSubscriptionPayment.email'),
          orderPhone: this.$t('eshopSubscriptionPayment.phone'),
          status: this.$t('eshopSubscriptionPayment.status'),
          comfortPaySmsStatus: this.$t('eshopSubscriptionPayment.comfortPaySmsStatus'),
          aboPeriodikStatus: this.$t('eshopSubscriptionPayment.subscriptionStatus.aboPeriodikExported'),
          nextPaymentAt: this.$t('eshopSubscriptionPayment.nextPaymentAt'),
          createdAt: this.$t('eshopSubscriptionPayment.created_at')
        },
        render: {
          order: function (data) {
            return '<a target="_blank" href="/shop/subscription/order/' + data + '">' + data + '</a>'
          },
          orderEmail: function (data) {
            if (data) {
              return '<a target="_blank" href="/feUser/' + this.getUserIdByEmail(data) + '/edit">' + data + '</a>'
            }
            return data
          }.bind(this),
          paymentResult: function (data) {
            return this.paymentResultTypeById(data).title
          }.bind(this),
          orderPayment: function (data) {
            return this.paymentTypeById(data).title
          }.bind(this),
          status: function (data) {
            return this.paymentStatusTypeById(data).title
          }.bind(this),
          aboPeriodikStatus: function (data) {
            if (data) {
              return '<i class="fas fa-check-circle"></i>'
            }
          }
        },
        actions: {
          detail: 'eshopSubscriptionPayment_detail'
        }
      }
    }
  },
  computed: {
    ...mapState([
      'callingAPI'
    ]),
    payments () {
      return this.$store.getters['eshopSubscriptionPayment/list']
    },
    products () {
      return this.$store.getters['eshopSubscriptionProduct/all']
    },
    totalCount () {
      return this.$store.getters['eshopSubscriptionPayment/totalCount']
    },
    pageCount () {
      return Math.ceil(this.$store.getters['eshopSubscriptionPayment/totalCount'] / config.defaults.list.limit)
    }
  },
  components: {
    appModuleView: ModuleView,
    appPreloader: Preloader,
    appSubscriptionPaymentFilter: SubscriptionPaymentFilter,
    appDataTable: DataTable
  },
  methods: {
    getPayments () {
      this.$store.dispatch('eshopSubscriptionPayment/fetch')
    },
    getUserIdByEmail (email) {
      return this.payments.find(payment => {
        if (email === payment.orderEmail) {
          return payment
        }
      }).orderFull.feUser
    },
    setPageAndGetRecords (page) {
      this.page = page
      this.$store.commit('eshopSubscriptionPayment/setPage', page)
      this.getPayments()
    }
  },
  mounted () {
    this.page = this.$store.getters['eshopSubscriptionPayment/page']
    this.getPayments()
    StoreService.fetchAll()
  }
}
</script>

<style lang="scss">

//.table-responsive {
//    td:last-child {
//        min-width: 155px;
//    }
//}
//.table-responsive {
//    td:last-child button {
//        margin-right: 5px;
//    }
//}

</style>
