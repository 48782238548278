<template>
  <section>
    <div class="card">
      <div class="card-body">
        <form>
          <div class="row">
            <div class="col-lg-1 col-md-6">
              <app-input v-model="filter.id" id="filter_id" :label="$t('eshopSubscriptionPayment.id')"></app-input>
            </div>
            <div class="col-lg-1 col-md-6">
              <app-input v-model="filter.variableSymbol" id="filter_vs"
                         :label="$t('eshopSubscriptionPayment.variableSymbol')"></app-input>
            </div>
            <div class="col-lg-1 col-md-6">
              <app-input v-model="filter.order" id="filter_order"
                         :label="$t('eshopSubscriptionPayment.order')"></app-input>
            </div>
            <div class="col-lg-1 col-md-6">
              <app-input v-model="filter.price" id="filter_price"
                         :label="$t('eshopSubscriptionPayment.price')"></app-input>
            </div>
            <div class="col-lg-2">
              <app-select
                v-model="filter.order_product"
                :options="products"
                :label="$t('eshopSubscriptionPayment.product')"
                track-by="id"
                id="filter_order_product"
                optionTitle="name"
              >
              </app-select>
            </div>
            <div class="col-lg-1">
              <app-select
                v-model="filter.order_payment"
                :options="paymentTypeValues"
                :label="$t('eshopSubscriptionPayment.payment')"
                track-by="id"
                id="filter_order_payment"
              >
              </app-select>
            </div>
            <div class="col-lg-1">
              <app-select
                v-model="filter.status"
                :options="paymentStatusTypeValues"
                :label="$t('eshopSubscriptionPayment.status')"
                track-by="id"
                id="filter_status"
              >
              </app-select>
            </div>
            <div class="col-lg-1">
              <app-select
                v-model="filter.paymentResult"
                :options="paymentResultTypeValues"
                :label="$t('eshopSubscriptionPayment.paymentResult')"
                track-by="id"
                id="filter_paymentResult"
              >
              </app-select>
            </div>
            <div class="col-lg-3">
              <button class="btn btn-success pull-right m-t-30" @click.prevent="filterPayments">
                {{ $t('eshopSubscriptionPayment.filter.search') }}
              </button>
              <button
                v-if="isExportButtonVisible"
                class="btn btn-success pull-right m-t-30"
                @click.prevent="exportPayments"
              >
                {{ $t('eshopSubscriptionPayment.filter.export') }}
              </button>
              <button class="btn btn-success pull-right m-t-30" data-test="payment_reset_filter"
                      @click.prevent="resetFilters">
                {{ $t('eshopSubscriptionPayment.filter.reset_filter') }}
              </button>
            </div>
            <div class="col-lg-2 col-md-6">
              <app-input v-model="filter.order_email" id="filter_order_email"
                         :label="$t('eshopSubscriptionPayment.email')"></app-input>
            </div>
            <div class="col-lg-2 col-md-6">
              <app-input v-model="filter.order_phone" id="filter_order_phone"
                         :label="$t('eshopSubscriptionPayment.phone')"></app-input>
            </div>
            <div class="col-lg-2 col-md-6">
              <app-datepicker
                type="date"
                v-model="filter.createdAtFrom"
                id="filter_createdAtFrom"
                :label="$t('eshopSubscriptionPayment.created_at_from')"
                disable-form-group
              >
              </app-datepicker>
            </div>
            <div class="col-lg-2 col-md-6">
              <app-datepicker
                type="date"
                v-model="filter.createdAtTo"
                id="filter_createdAtTo"
                :label="$t('eshopSubscriptionPayment.created_at_to')"
                disable-form-group
              >
              </app-datepicker>
            </div>
            <div class="col-lg-2 col-md-6">
              <app-datepicker
                type="date"
                v-model="filter.nextSmsPaymentAt"
                id="filter_nextSmsPaymentAt"
                :label="$t('eshopSubscriptionPayment.nextSmsPaymentAt')"
                disable-form-group
              >
              </app-datepicker>
            </div>
            <div class="col-lg-2 col-md-6">
              <app-datepicker
                type="date"
                v-model="filter.nextComfortPayPaymentAt"
                id="filter_nextComfortPayPaymentAt"
                :label="$t('eshopSubscriptionPayment.nextComfortPayPaymentAt')"
                disable-form-group
              >
              </app-datepicker>
            </div>
          </div>
        </form>
      </div>
    </div>
  </section>
</template>
<script>
import Input from '../form/inputs/Input'
import Select from '../form/select/Select'
import PaymentFilterModel from '../../model/eshop/SubscriptionPaymentFilter'
import Datepicker from '../form/Datepicker'
import PaymentTypeMixin from '../mixins/valueObject/eshop/PaymentTypeMixin'
import PaymentResultTypeMixin from '../mixins/valueObject/eshop/PaymentResultTypeMixin'
import PaymentStatusTypeMixin from '../mixins/valueObject/eshop/PaymentStatusTypeMixin'
import DownloadFileService from '../../services/eshop/DownloadFileService'
import NotifyService from '../../services/NotifyService'
import PermissionService from '@/services/PermissionService'

export default {
  name: 'SubscriptionPaymentFilter',
  mixins: [PaymentTypeMixin, PaymentResultTypeMixin, PaymentStatusTypeMixin],
  data () {
    return {
      isLoading: false,
      filter: this._.cloneDeep(PaymentFilterModel),
      advancedFilter: false
    }
  },
  computed: {
    isExportButtonVisible () {
      const permission = PermissionService.REQUIRED_PERMISSIONS.ESHOP_PAYMENT_PERMISSIONS.exportButton
      return this.$store.getters['user/hasPermission'](permission)
    },
    products () {
      return this.$store.getters['eshopSubscriptionProduct/all']
    }
  },
  components: {
    appInput: Input,
    appSelect: Select,
    appDatepicker: Datepicker
  },
  methods: {
    filterPayments () {
      this.$store.commit('eshopSubscriptionPayment/setPage', 1)
      this.$store.commit('eshopSubscriptionPayment/setFilter', this.filter)
      this.$store.dispatch('eshopSubscriptionPayment/fetch')
    },
    exportPayments () {
      this.$store.dispatch('eshopSubscriptionPayment/export')
        .then((res) => {
          DownloadFileService.download(res, 'platby_', '.csv')
        })
        .catch(error => {
          console.log(error)
          NotifyService.setErrorMessage(this.$t('eshop.notify.export_failed') + ' (' + error + ')')
        })
    },
    resetFilters () {
      this.filter = this._.cloneDeep(PaymentFilterModel)
      this.filterPayments()
    }
  },
  beforeMount () {
    this.filter = this.$store.getters['eshopSubscriptionPayment/filter']
  }
}
</script>

<style lang="scss">

</style>
