import Store from '../../store'

const fetchAll = function () {
  Store.dispatch('eshopSubscriptionProduct/fetchAll', null, { root: true })
  Store.dispatch('eshopDigitalService/fetchAll', null, { root: true })
  Store.dispatch('eshopAboProduct/fetchAll', null, { root: true })
  Store.dispatch('eshopAboEdition/fetchAll', null, { root: true })
  Store.dispatch('eshopAboPeriodikPrice/fetchAll', null, { root: true })
}

export default {
  fetchAll
}
