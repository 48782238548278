export const PAYMENT_TYPE_FREE = 'free'
export const PAYMENT_TYPE_TRANSFER = 'transfer'
export const PAYMENT_TYPE_CARDPAY = 'cardpay'
export const PAYMENT_TYPE_TATRAPAY = 'tatrapay'
export const PAYMENT_TYPE_COMFORTPAY = 'comfortpay'
export const PAYMENT_TYPE_SMS_RECURRING = 'sms_recurring'
export const PAYMENT_TYPE_CSOB_CARDPAY = 'csob_cardpay'
export const PAYMENT_TYPE_CSOB_GOOGLEPAY = 'csob_googlepay'
export const PAYMENT_TYPE_CSOB_MASTERCARD_MOBILE = 'csob_mastercard_mob'

export const PAYMENT_TYPE_DEFAULT = PAYMENT_TYPE_FREE

export default {
  computed: {
    paymentTypeValues () {
      return [
        {
          id: PAYMENT_TYPE_FREE,
          title: this.$t('eshop.payment_type.free')
        },
        {
          id: PAYMENT_TYPE_TRANSFER,
          title: this.$t('eshop.payment_type.transfer')
        },
        {
          id: PAYMENT_TYPE_CARDPAY,
          title: this.$t('eshop.payment_type.cardpay')
        },
        {
          id: PAYMENT_TYPE_TATRAPAY,
          title: this.$t('eshop.payment_type.tatrapay')
        },
        {
          id: PAYMENT_TYPE_COMFORTPAY,
          title: this.$t('eshop.payment_type.comfortpay')
        },
        {
          id: PAYMENT_TYPE_SMS_RECURRING,
          title: this.$t('eshop.payment_type.sms_recurring')
        },
        {
          id: PAYMENT_TYPE_CSOB_CARDPAY,
          title: this.$t('eshop.payment_type.csob_cardpay')
        },
        {
          id: PAYMENT_TYPE_CSOB_GOOGLEPAY,
          title: this.$t('eshop.payment_type.csob_googlepay')
        },
        {
          id: PAYMENT_TYPE_CSOB_MASTERCARD_MOBILE,
          title: this.$t('eshop.payment_type.csob_mastercard_mob')
        }
      ]
    }
  },
  methods: {
    paymentTypeById (id) {
      var item = id
      this.paymentTypeValues.forEach(function (payment) {
        if (id === payment.id) {
          item = payment
        }
      })
      return item
    },
    isRecurring (payment) {
      return [PAYMENT_TYPE_COMFORTPAY, PAYMENT_TYPE_SMS_RECURRING].includes(payment.orderPayment)
    },
    isActive (payment) {
      if (payment.orderPayment === PAYMENT_TYPE_COMFORTPAY) {
        return payment.comfortPayStatus === 'active'
      }
      if (payment.orderPayment === PAYMENT_TYPE_SMS_RECURRING) {
        return payment.smsStatus === 'active'
      }
    },
    isComfortPay (payment) {
      return payment.orderPayment === PAYMENT_TYPE_COMFORTPAY
    }
  }
}
