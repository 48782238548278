const download = function (response, filenamePrefix, filenameSuffix) {
  const d = new Date()
  const blob = new Blob([response.data], {
    type: response.headers['content-type']
  })
  const link = document.createElement('a')
  link.href = window.URL.createObjectURL(blob)
  link.download = filenamePrefix + d.getDate() + '_' + (d.getMonth() + 1) + '_' + d.getFullYear() + filenameSuffix
  document.body.appendChild(link)
  link.click()
  link.remove()
}

export default {
  download
}
