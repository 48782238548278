export const PAYMENT_RESULT_TYPE_SUCCESS = 'success'
export const PAYMENT_RESULT_TYPE_FAILED = 'failed'
export const PAYMENT_RESULT_TYPE_UNKNOWN = 'unknown'

export const PAYMENT_RESULT_TYPE_DEFAULT = PAYMENT_RESULT_TYPE_SUCCESS

export default {
  computed: {
    paymentResultTypeValues () {
      return [
        {
          id: PAYMENT_RESULT_TYPE_SUCCESS,
          title: this.$t('eshop.payment_result_type.success')
        },
        {
          id: PAYMENT_RESULT_TYPE_FAILED,
          title: this.$t('eshop.payment_result_type.failed')
        },
        {
          id: PAYMENT_RESULT_TYPE_UNKNOWN,
          title: this.$t('eshop.payment_result_type.unknown')
        }
      ]
    }
  },
  methods: {
    paymentResultTypeById (id) {
      var item = id
      this.paymentResultTypeValues.forEach(function (paymentResult) {
        if (id === paymentResult.id) {
          item = paymentResult
        }
      })
      return item
    }
  }
}
