export const PAYMENT_STATUS_TYPE_COMMON = 'common'
export const PAYMENT_STATUS_TYPE_FIRST = 'first'
export const PAYMENT_STATUS_TYPE_RECURRING = 'recurring'

export const PAYMENT_TYPE_DEFAULT = PAYMENT_STATUS_TYPE_COMMON

export default {
  computed: {
    paymentStatusTypeValues () {
      return [
        {
          id: PAYMENT_STATUS_TYPE_COMMON,
          title: this.$t('eshop.payment_status_type.common')
        },
        {
          id: PAYMENT_STATUS_TYPE_FIRST,
          title: this.$t('eshop.payment_status_type.first')
        },
        {
          id: PAYMENT_STATUS_TYPE_RECURRING,
          title: this.$t('eshop.payment_status_type.recurring')
        }
      ]
    }
  },
  methods: {
    paymentStatusTypeById (id) {
      var item = id
      this.paymentStatusTypeValues.forEach(function (paymentStatus) {
        if (id === paymentStatus.id) {
          item = paymentStatus
        }
      })
      return item
    }
  }
}
